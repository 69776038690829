@import "primeicons/primeicons.css"
@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Offside&display=swap')
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap')

//$sm	Breakpoint of screens such as phones.	576px
//$md	Breakpoint of screens such as tablets.	768px
//$lg	Breakpoint of screens such as notebook monitors.	992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

*
    font-family: "DM Sans", sans-serif
    font-optical-sizing: auto

body
    //font-family: "Electrolize", sans-serif
    //font-family: var(--font-family)
    padding: 0
    margin: 0
    background: linear-gradient(135deg, var(--surface-ground), var(--surface-50))

.electrolize
    font-family: "Electrolize", sans-serif

.p-datatable
    .p-datatable-thead
        & > tr
            & > th
                background: var(--surface-section) !important

.label-required:after
    content: "*"
    color: var(--red-500)
    margin-left: 2px

.positive
    color: var(--primary-color) !important

.negative
    color: var(--red-400) !important

input.ng-invalid.ng-touched
    border-color: var(--red-400)

.warning-text
    color: var(--red-400)

.minimal-card
    .p-card-content
        padding-top: 0

.primary-border
    border: 1px solid var(--primary-color)

body
    .card-border
        border-radius: 1rem
        box-shadow: none

    &.dark
        .card-border
            border: 1px solid #006053

        .orbit
            border: 2px var(--surface-100) dashed

    &.light
        .card-border
            border: 1px solid var(--primary-color)

        .orbit
            border: 2px var(--surface-300) dashed

.overlay-no-padding
    .p-overlaypanel-content
        padding: 4px
        width: 270px

.fit-content
    height: fit-content

.p-inputnumber-input
    width: 100%

.table-layout-fixed
    table-layout: fixed

.p-button-label
    font-weight: 500

@media (max-width: 576px)
    .remove-padding
        .p-card-body
            padding: 0
